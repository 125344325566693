import React from 'react';

import {
    StyledQuestionsAndAnswers,
    StyledQuestionsAndAnswersTitle,
    StyledQuestionsAndAnswersTitleSeparator,
    StyledQuestionsAndAnswersCollection,
    StyledQuestionAndAnswer,
    StyledQuestion,
    StyledAnswer
} from './mt-cms-questions-and-answers.styles';

const parseAnswers = (children, markDefs) => children.map((child, index) => {
    if (!child.marks.length) {
        // eslint-disable-next-line react/no-array-index-key
        return <span key={index}>{child.text}</span>;
    }

    const mark = markDefs.find((markDef) => markDef._key === child.marks[0]);

    return (
        <a
            data-testid={mark._key}
            href={mark.href}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            rel="noopener noreferrer"
            target="_blank"
        >
            {child.text}
        </a>
    );
});

const MtCMSQuestionsAndAnswers = ({faqData}) =>
    <StyledQuestionsAndAnswers>
        <StyledQuestionsAndAnswersTitle>{faqData.header}</StyledQuestionsAndAnswersTitle>
        <StyledQuestionsAndAnswersTitleSeparator />
        <StyledQuestionsAndAnswersCollection>
            {faqData.qAndAs.map((qa, index) =>
                // eslint-disable-next-line react/no-array-index-key
                <StyledQuestionAndAnswer key={`qa-${index}`}>
                    <StyledQuestion>{qa.question}</StyledQuestion>
                    <StyledAnswer>{parseAnswers(qa.answerRaw[0].children, qa.answerRaw[0].markDefs)}</StyledAnswer>
                </StyledQuestionAndAnswer>
            )}
        </StyledQuestionsAndAnswersCollection>
    </StyledQuestionsAndAnswers>;

export default MtCMSQuestionsAndAnswers;
