import React from 'react';

import {
    StyledImageListWrapper,
    StyledImageListHeader,
    StyledImageList,
    StyledImageListItem,
    StyledImageListItemImage,
    StyledImageListItemText
} from './mt-cms-image-list.styles';

const MtCMSImageList = ({photoCards}) => (
    <StyledImageListWrapper>
        <StyledImageListHeader>{photoCards.header}</StyledImageListHeader>
        <StyledImageList>
            {photoCards.photoCards.map((photoCard) =>
                <StyledImageListItem key={`qa-${photoCard.description}`}>
                    <StyledImageListItemImage url={photoCard.image.asset.url} />
                    <StyledImageListItemText>{photoCard.description}</StyledImageListItemText>
                </StyledImageListItem>
            )}
        </StyledImageList>
    </StyledImageListWrapper>
);

export default MtCMSImageList;
