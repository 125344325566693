import {hyvee} from '@hy-vee/themes';

export const defaultMealtimeTheme = {
    ...hyvee,
    primary: '#80BC00',
    primaryActive: '#4b690b',
    primaryHover: '#658d10',
    primaryLight: '#cbe98d'
};

export const mealtimeThemeFontSizes = {
    contentFontSize: 'min(1.75vw, 22px)',
    headerFontSize: 'min(4.4vw, 50px)',
    heroFontSize: 'min(9vw, 80px)',
    subHeaderFontSize: 'min(4.0vw, 30px)',
    titleFontSize: 'min(3vw, 36px)'
};

export const mealtimeBrandLogos = [
    {
        alt: 'The Hibachi logo',
        imageUrl: 'https://721695561982f42f75d3-9784fd013699897797da8b04ffded30e.ssl.cf2.rackcdn.com/the-hibachi-logo.svg'
    },
    {
        alt: 'Mia Pizza logo',
        imageUrl: 'https://721695561982f42f75d3-9784fd013699897797da8b04ffded30e.ssl.cf2.rackcdn.com/mia-pizza-logo.jpg'
    },
    {
        alt: 'Hickory House logo',
        imageUrl: 'https://721695561982f42f75d3-9784fd013699897797da8b04ffded30e.ssl.cf2.rackcdn.com/hickory-house-comfort-foods-logo.svg'
    },
    {
        alt: 'Market Grille logo',
        imageUrl: 'https://721695561982f42f75d3-9784fd013699897797da8b04ffded30e.ssl.cf2.rackcdn.com/market-grille-logo.svg'
    },
    {
        alt: 'Wahlburgers logo',
        imageUrl: 'https://721695561982f42f75d3-9784fd013699897797da8b04ffded30e.ssl.cf2.rackcdn.com/wahlburgers-logo.jpg'
    },
    {
        alt: 'HyChi logo',
        imageUrl: 'https://721695561982f42f75d3-9784fd013699897797da8b04ffded30e.ssl.cf2.rackcdn.com/hychi-logo.svg'
    },
    {
        alt: 'Nori Sushi logo',
        imageUrl: 'https://721695561982f42f75d3-9784fd013699897797da8b04ffded30e.ssl.cf2.rackcdn.com/nori-sushi-logo.svg'
    }
];

