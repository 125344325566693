import React from 'react';

import FeatureToggleContext from './context';

const useFeatureToggle = () => {
    const featureToggleContext = React.useContext(FeatureToggleContext);

    if (featureToggleContext === null) {
        throw new Error('Feature Toggle Hooks must be inside the Feature Toggle Provider');
    }

    return featureToggleContext;
};

export {useFeatureToggle};
