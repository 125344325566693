import styled from 'styled-components';

export const StyledMealTimeLanding = styled.div`
    a {
        font-weight: 500;
        color: ${(props) => props.theme.primary};

        &:active {
            color: ${(props) => props.theme.primaryActive};
        }

        &:visited {
            color: ${(props) => props.theme.primary};
        }
    }
`;
