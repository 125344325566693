import styled from 'styled-components';
import {Button} from '@hy-vee/web-core/lib/components/button';

import {mealtimeThemeFontSizes} from '../../styles/mealtime-theme';

export const StyledVideoHeader = styled.div`
    @media (min-width: 600px) {
        margin-bottom: 20px;
    }
`;

export const StyledVideoHeaderHeader = styled.div`
    position: relative;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    ${(props) => `height: ${props.videoHeight / props.videoWidth * 100}vw;`};
    ${(props) => `background-image: url('${props.backgroundImageUrl}');`};
`;

export const StyledVideoHeaderContent = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const StyledVideoHeaderPlaybackControls = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 30px;
    bottom: 30px;
    width: 40px;
    height: 40px;
    transition-property: opacity;
    transition-duration: 0.2s;
    opacity: 0.6;
    cursor: pointer;

    &:hover {
        opacity: 1.0;
    }

    @media (max-width: 600px) {
        display: none;
    }
`;

export const StyledVideoHeaderVideo = styled.video`
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const StyledVideoHeaderOverlayHeroButton = styled(Button)`
    margin-top: 3vw;

    @media (max-width: 600px) {
        display: none;
    }
`;

export const StyledVideoHeaderFooterButton = styled(Button)`
    margin-bottom: 20px;

    @media (min-width: 600px) {
        display: none;
    }
`;

export const StyledVideoHeaderOverlayHeroText = styled.div`
    font-size: ${mealtimeThemeFontSizes.heroFontSize};
    line-height: 1.1;
    color: white;
    white-space: pre-line;
    text-align: center;
    font-weight: 700;

    @media (min-width: 600px) {
        font-size: 8vw;
    }
`;

export const StyledVideoHeaderOverlayImage = styled.img`
    margin-bottom: max(10px, 1vw);
    width: 30vw;
    max-width: 409px;

    @media (min-width: 600px) {
        font-size: 8vw;
    }
`;

export const StyledVideoHeaderFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const StyledVideoHeaderFooterBrandLogos = styled.div`
    margin-top: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 4vw;
    padding-right: 4vw;
`;

export const StyledVideoHeaderFooterBrandLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8vw;
    height: 8vw;
    margin-right: 4vw;
    min-width: 70px;
    min-height: 70px;

    img {
        max-width: 100%;
        width: auto;
    }

    &:last-child {
        margin-right: 0;
    }
`;

export const StyledVideoHeaderFooterTitle = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        font-size: ${mealtimeThemeFontSizes.headerFontSize};
        line-height: normal;
        padding-left: 2vw;
        padding-right: 2vw;
        border-right: 2px solid ${(props) => props.theme.primary};
    }

    div:last-child {
        border-right: none;
    }

    @media (max-width: 600px) {
        display: none;
    }
`;

export const StyledVideoHeaderFooterDescription = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 12vw;
    margin-right: 12vw;
    text-align: center;
    font-size: 4.4vw;
    line-height: normal;
    white-space: inherit;

    @media (min-width: 600px) {
        font-size: 4.0vw;
        white-space: pre-line;
        margin-left: 0;
        margin-right: 0;
    }

    @media (min-width: 830px) {
        font-size: 32px;
        white-space: pre-line;
        margin-left: 0;
        margin-right: 0;
    }
`;
