import React from 'react';
import {Button} from '@hy-vee/web-core/lib/components/button';

import {ScreenSize} from '../../contexts/screen-context';
import {orderNow} from '../../utils/mealtime-utils';

import {
    StyledFloatingTextBlock,
    StyledFloatingTextBlockContent,
    StyledFloatingTextBlockContentStandaloneTitle,
    StyledFloatingTextBlockContentTextBlock,
    StyledFloatingTextBlockContentTextBlockCap,
    StyledFloatingTextBlockContentTextBlockDivider,
    StyledFloatingTextBlockContentTextBlockTitle,
    StyledFloatingTextBlockContentTextBlockText,
    StyledFloatingTextBlockContentTextBlockFooter,
    StyledFloatingTextBlockFooter,
    StyledFloatingTextBlockFooterText
} from './mt-cms-floating-text-block.styles';

const buttonSize = (screenWidth) => screenWidth > 1100 ? 'large' : 'medium';

const MtCMSFloatingTextBlock = ({photoBanner}) => (
    <ScreenSize>
        {({screenWidth}) =>
            <StyledFloatingTextBlock>
                <StyledFloatingTextBlockContent
                    backgroundImageHeight={670}
                    backgroundImageUrl={photoBanner.backgroundImage.asset.url}
                    backgroundImageWidth={1440}
                >
                    {screenWidth < 600 ?
                        <StyledFloatingTextBlockContentStandaloneTitle>{photoBanner.header}</StyledFloatingTextBlockContentStandaloneTitle>
                        :
                        <StyledFloatingTextBlockContentTextBlock>
                            <StyledFloatingTextBlockContentTextBlockCap />
                            <StyledFloatingTextBlockContentTextBlockTitle>{photoBanner.header}</StyledFloatingTextBlockContentTextBlockTitle>
                            <StyledFloatingTextBlockContentTextBlockDivider />
                            <StyledFloatingTextBlockContentTextBlockText>{photoBanner.subheaderRaw[0].children[0].text}</StyledFloatingTextBlockContentTextBlockText>
                            <StyledFloatingTextBlockContentTextBlockFooter>
                                <Button
                                    data-testid="mealtime-delivery-order-now-button-desktop"
                                    onClick={() => orderNow(photoBanner.button.buttonLink)}
                                    size={buttonSize(screenWidth)}
                                >
                                    {photoBanner.button.buttonText}
                                </Button>
                            </StyledFloatingTextBlockContentTextBlockFooter>
                        </StyledFloatingTextBlockContentTextBlock>}
                </StyledFloatingTextBlockContent>
                {screenWidth < 600 ?
                    <StyledFloatingTextBlockFooter>
                        <StyledFloatingTextBlockFooterText>{photoBanner.subheaderRaw[0].children[0].text}</StyledFloatingTextBlockFooterText>
                        <Button
                            data-testid="mealtime-delivery-order-now-button-mobile"
                            onClick={() => orderNow(photoBanner.button.buttonLink)}
                        >
                            {photoBanner.button.buttonText}
                        </Button>
                    </StyledFloatingTextBlockFooter>
                    :
                    null}
            </StyledFloatingTextBlock>}
    </ScreenSize>

);

export default MtCMSFloatingTextBlock;
