import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {isIE} from 'react-device-detect';

import {useCustomerContext} from '../../contexts/customer-context';
import HeaderPage from '../header-page';
import {defaultMealtimeTheme} from '../../styles/mealtime-theme';
import {isServerSide} from '../../utils/env';
import {getSanityService} from '../../services/sanity-service';

import CMSVideoHeader from './mt-cms-video-header';
import CMSImageList from './mt-cms-image-list';
import CMSFloatingTextBlock from './mt-cms-floating-text-block';
import CMSQuestionsAndAnswers from './mt-cms-questions-and-answers';
import CMSIe from './mt-ie';
import {
    StyledMealTimeLanding
} from './mealtime-landing.styles';

const title = 'Mealtime To Go: Order Food Online for Curbside Pickup | Hy-Vee';
const description = 'Order ready-to-eat or heat-and-serve restaurant meals online in just minutes. Choose from breakfast, lunch, or dinner for takeout or no-contact delivery.';

const seo = {
    description,
    openGraph: {
        description,
        images: [
            {
                alt: title,
                height: 630,
                url:
                    'https://76c35e76589404e46312-6ec6d4c514ba6d410de8bce9cae9c292.ssl.cf2.rackcdn.com/MTO/Mealtime%20Landing%20Page/open-graph.jpg',
                width: 1200
            }
        ],
        'site_name': 'Hy-Vee',
        title,
        url: 'https://hy-vee.com/mealtime'
    },
    title
};

const getDataByType = (sanityData, type, index = 0) => sanityData.pageContent.filter((content) => content.__typename === type)[index];

const MealtimeLanding = () => {
    const [sanityData, setSanityData] = useState(null);
    const [sanityTheme, setSanityTheme] = useState(defaultMealtimeTheme);
    const {customer, isAuthenticated, loading} = useCustomerContext();

    useEffect(() => {
        const load = async () => {
            const response = await getSanityService('/api/mealtime');

            if (response) {
                setSanityData(response[0]);
                setSanityTheme({
                    ...sanityTheme,
                    primary: response[0].theme.primaryColor.hex,
                    primaryActive: response[0].theme.primaryActive.hex,
                    primaryHover: response[0].theme.primaryHover.hex,
                    primaryLight: response[0].theme.primaryLight.hex
                });
            }
        };

        load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!sanityData || loading) {
        return null;
    }

    return (
        <HeaderPage
            customer={customer}
            isAuthenticated={isAuthenticated}
            seo={seo}
            store={customer ? customer.store : undefined}
        >
            <ThemeProvider theme={sanityTheme}>
                <StyledMealTimeLanding>
                    {isIE && !isServerSide() &&
                        <div data-testid="bad-browser">
                            <CMSIe
                                brandLogos={getDataByType(sanityData, 'CmsLandingPagePhotoCards')}
                                faqData={getDataByType(sanityData, 'CmsLandingPageFAQs')}
                                heroBanner={getDataByType(sanityData, 'CmsLandingPageHeroBanner')}
                                photoBanner={getDataByType(sanityData, 'CmsLandingPagePhotoBanner')}
                                photoCards={getDataByType(sanityData, 'CmsLandingPagePhotoCards', 1)}
                                videoHeaderFooter={getDataByType(sanityData, 'CmsLandingPageMultiHeaderContentBlock')}
                            />
                        </div>}
                    {isServerSide() || !isIE &&
                        <div data-testid="good-browser">
                            <CMSVideoHeader
                                brandLogos={getDataByType(sanityData, 'CmsLandingPagePhotoCards')}
                                heroBanner={getDataByType(sanityData, 'CmsLandingPageHeroBanner')}
                                videoHeaderFooter={getDataByType(sanityData, 'CmsLandingPageMultiHeaderContentBlock')}
                            />
                            <CMSImageList photoCards={getDataByType(sanityData, 'CmsLandingPagePhotoCards', 1)} />
                            <CMSFloatingTextBlock photoBanner={getDataByType(sanityData, 'CmsLandingPagePhotoBanner')} />
                            <CMSQuestionsAndAnswers faqData={getDataByType(sanityData, 'CmsLandingPageFAQs')} />
                        </div>}
                </StyledMealTimeLanding>
            </ThemeProvider>
        </HeaderPage>
    );
};

// Forces page to be fully rendered each time and not cached on build.
// eslint-disable-next-line require-await
MealtimeLanding.getInitialProps = async () => ({});

MealtimeLanding.wantsCustomerContext = () => true;

export default MealtimeLanding;
