import React from 'react';
import {GlobalNavigationHeader, GlobalNavigationFooter, GlobalNavigationProvider} from '@hy-vee/global-navigation';
import {useRouter} from 'next/router';
import {NextSeo} from 'next-seo';

import {SEASONS_SEARCH, SEASONS_SEARCH_IDEAS} from '../enums/feature-toggle-names';
import {useFeatureToggle} from '../contexts/feature-toggle';
import {StyledRelativeContainer} from '../styles/styles';
import {info} from '../services/logging-service';

const HeaderPage = ({
    children,
    seo
}) => {
    const router = useRouter();
    const {featureEnabled} = useFeatureToggle();
    const isSeasonsSearchFeatureEnabled = featureEnabled(SEASONS_SEARCH);
    const isEcommIdeasEnhancedSearchWeb = featureEnabled(SEASONS_SEARCH_IDEAS);
    const handleRouteChange = (url) => {
        router.push(url);
    };

    const clientPaths = {
        apiPrefix: '/pages/api',
        loginPath: '/pages/login',
        logoutPath: '/pages/logout'
    };

    const logErrorMessage = (error) => info(router.pathname, 'Global Nav Error', error);

    return (
        <GlobalNavigationProvider
            clientPaths={clientPaths}
            onGlobalNavError={logErrorMessage}
        >
            <NextSeo {...seo} />
            <StyledRelativeContainer>
                <GlobalNavigationHeader
                    isEcommIdeasEnhancedSearchWeb={isEcommIdeasEnhancedSearchWeb}
                    isSeasonsSearchFeatureEnabled={isSeasonsSearchFeatureEnabled}
                    onRouteChange={handleRouteChange}
                />
                <main>{children}</main>
                <GlobalNavigationFooter />
            </StyledRelativeContainer>
        </GlobalNavigationProvider>
    );
};

export default HeaderPage;
