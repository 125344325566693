import styled from 'styled-components';

import {mealtimeThemeFontSizes} from '../../styles/mealtime-theme';

export const StyledFloatingTextBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledFloatingTextBlockContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    ${(props) => `height: ${props.backgroundImageHeight / props.backgroundImageWidth * 100}vw;`};
    ${(props) => `background-image: url('${props.backgroundImageUrl}');`};

    @media (min-width: 600px) {
        padding: 3.5vw;
        justify-content: flex-start;
        align-items: stretch;
    }
`;

export const StyledFloatingTextBlockContentStandaloneTitle = styled.div`
    color: white;
    font-weight: 500;
    font-size: ${mealtimeThemeFontSizes.heroFontSize};
    line-height: normal;
    white-space: pre-line;
    text-align: center;
`;

export const StyledFloatingTextBlockContentTextBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 40%;
    max-height: 476px;
    max-width: 448px;
    border-radius: 1vw;
    background-color: white;
    overflow: hidden;
`;

export const StyledFloatingTextBlockContentTextBlockCap = styled.div`
    background-color: ${(props) => props.theme.primary};
    width: 100%;
    height: 6%;
`;

export const StyledFloatingTextBlockContentTextBlockDivider = styled.div`
    background-color: ${(props) => props.theme.primary};
    width: 50%;
    height: 2px;
`;

export const StyledFloatingTextBlockContentTextBlockTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: ${mealtimeThemeFontSizes.titleFontSize};
    text-align: center;
    line-height: normal;
    white-space: pre-line;
    padding-left: min(3vw, 20px);
    padding-right: min(3vw, 20px);
`;

export const StyledFloatingTextBlockContentTextBlockText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: ${mealtimeThemeFontSizes.contentFontSize};
    text-align: center;
    line-height: normal;
    white-space: pre-line;
    padding-left: min(3vw, 20px);
    padding-right: min(3vw, 20px);
`;

export const StyledFloatingTextBlockContentTextBlockFooter = styled.div`
    margin-bottom: min(3.5vw, 30px);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledFloatingTextBlockFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6vw;
    padding-left: 5vw;
    padding-right: 5vw;
`;

export const StyledFloatingTextBlockFooterText = styled.div`
    font-size: 4.4vw;
    text-align: center;
    line-height: normal;
    white-space: pre-line;
    margin-bottom: 6vw;
`;
