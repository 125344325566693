import React from 'react';
import {Button} from '@hy-vee/web-core/lib/components/button';

import {orderNow} from '../../utils/mealtime-utils';

import {
    StyledIEFullWidthImage,
    StyledIEPageContent,
    StyledIEHeader,
    StyledIEPage,
    StyledIEHeaderContent,
    StyledIEBrandLogos,
    StyledIEBrandLogo,
    StyledIEHeadline,
    StyledIECurbside,
    StyledIECurbsideItem,
    StyledIECurbsideItemImage,
    StyledIECurbsideItemText,
    StyledIEDelivery,
    StyledIEDeliveryContent,
    StyledIEDeliveryFloatingBoxCap,
    StyledIEDeliveryFloatingBox,
    StyledIEDeliveryFloatingBoxTitle,
    StyledIEDeliveryFloatingBoxDivider,
    StyledIEDeliveryFloatingBoxText,
    StyledIEQuestionAndAnswers,
    StyledIEHeaderHeroText,
    StyledIEHeaderHeroButton,
    StyledIEHeaderHeroLogo,
    StyledIEHeaderVideo,
    StyledIEHeadlineText,
    StyledIEHeadlineSubext
} from './mt-ie.styles';
import {StyledAnswer, StyledQuestion, StyledQuestionAndAnswer} from './mt-cms-questions-and-answers.styles';

const parseAnswers = (children, markDefs) => children.map((child, index) => {
    if (!child.marks.length) {
        // eslint-disable-next-line react/no-array-index-key
        return <span key={index}>{child.text}</span>;
    }

    const mark = markDefs.find((markDef) => markDef._key === child.marks[0]);

    return (
        <a
            data-testid={mark._key}
            href={mark.href}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            rel="noopener noreferrer"
            target="_blank"
        >
            {child.text}
        </a>
    );
});

const getBrandLogos = (brandLogos) =>
    brandLogos.photoCards.map((logo) => (
        <StyledIEBrandLogo key={logo.imageAlt}>
            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
            <img
                alt={logo.imageAlt}
                role="img"
                src={logo.image.asset.url}
            />
        </StyledIEBrandLogo>
    ));

const MtIe = ({brandLogos, faqData, heroBanner, photoBanner, photoCards, videoHeaderFooter}) =>
    <StyledIEPage>
        <StyledIEPageContent>
            <StyledIEHeader>
                <StyledIEFullWidthImage
                    height={680}
                    src={heroBanner.backgroundImage.asset.url}
                    width={1440}
                />
                <StyledIEHeaderVideo
                    autoPlay
                    data-testid={heroBanner.backgroundVideoAlt}
                    loop
                    muted
                    src={heroBanner.backgroundVideoUrl}
                />
                <StyledIEHeaderContent>
                    <StyledIEHeaderHeroLogo
                        alt={heroBanner.logoAltText}
                        height={101}
                        src={heroBanner.logo.asset.url}
                        width={409}
                    />
                    <StyledIEHeaderHeroText>{heroBanner.header}</StyledIEHeaderHeroText>
                    <StyledIEHeaderHeroButton
                        data-testid="mealtime-header-order-now-button-desktop-2"
                        onClick={() => orderNow(heroBanner.buttons[0].buttonLink)}
                        size="large"
                    >
                        {heroBanner.buttons[0].buttonText}
                    </StyledIEHeaderHeroButton>
                </StyledIEHeaderContent>
            </StyledIEHeader>
            <StyledIEBrandLogos>
                {getBrandLogos(brandLogos)}
            </StyledIEBrandLogos>
            <StyledIEHeadline>
                <StyledIEHeadlineText>
                    {videoHeaderFooter.headers.map((header) =>
                        <div key={`qa-${header}`}>{header}</div>
                    )}
                </StyledIEHeadlineText>
                <StyledIEHeadlineSubext>{videoHeaderFooter.contentRaw[0].children[0].text}</StyledIEHeadlineSubext>
            </StyledIEHeadline>
            <StyledIECurbside>
                {photoCards.photoCards.map((photoCard) =>
                    <StyledIECurbsideItem key={`qa-${photoCard.description}`}>
                        <StyledIECurbsideItemImage
                            url={photoCard.image.asset.url}
                        />
                        <StyledIECurbsideItemText>{photoCard.description}</StyledIECurbsideItemText>
                    </StyledIECurbsideItem>
                )}
            </StyledIECurbside>
            <StyledIEDelivery>
                <StyledIEFullWidthImage
                    height={670}
                    src={photoBanner.backgroundImage.asset.url}
                    width={1440}
                />
                <StyledIEDeliveryContent>
                    <StyledIEDeliveryFloatingBox>
                        <StyledIEDeliveryFloatingBoxCap />
                        <StyledIEDeliveryFloatingBoxTitle>
                            {photoBanner.header}
                        </StyledIEDeliveryFloatingBoxTitle>
                        <StyledIEDeliveryFloatingBoxDivider />
                        <StyledIEDeliveryFloatingBoxText>
                            {photoBanner.subheaderRaw[0].children[0].text}
                        </StyledIEDeliveryFloatingBoxText>
                        <Button
                            data-testid="mealtime-delivery-order-now-button-desktop"
                            onClick={() => orderNow(photoBanner.button.buttonLink)}
                            size="large"
                        >
                            {photoBanner.button.buttonText}
                        </Button>
                    </StyledIEDeliveryFloatingBox>
                </StyledIEDeliveryContent>
            </StyledIEDelivery>
            <StyledIEQuestionAndAnswers>
                {faqData.qAndAs.map((qa, index) =>
                    // eslint-disable-next-line react/no-array-index-key
                    <StyledQuestionAndAnswer key={`qa-${index}`}>
                        <StyledQuestion>{qa.question}</StyledQuestion>
                        <StyledAnswer>{parseAnswers(qa.answerRaw[0].children, qa.answerRaw[0].markDefs)}</StyledAnswer>
                    </StyledQuestionAndAnswer>
                )}
            </StyledIEQuestionAndAnswers>
        </StyledIEPageContent>
    </StyledIEPage>;

export default MtIe;
