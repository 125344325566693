import styled from 'styled-components';

import {mealtimeThemeFontSizes} from '../../styles/mealtime-theme';

export const StyledImageListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F5F5F5;
    padding-bottom: 8vw;

    @media (min-width: 600px) {
        margin-bottom: 3vw;
        background-color: initial;
        padding-bottom: 0;
    }
`;

export const StyledImageListHeader = styled.div`
    display: block;
    font-size: 24px;
    margin-top: 3vw;
    margin-bottom: 3vw;

    @media (min-width: 600px) {
        display: none;
    }
`;

export const StyledImageList = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 8vw;
    margin-right: 8vw;

    @media (min-width: 600px) {
        margin-left: 4vw;
        margin-right: 4vw;
        flex-direction: row;
    }
`;

export const StyledImageListItem = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4vw;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 600px) {
        flex-direction: column;
        margin-top: 0;
        margin-right: 3vw;
        margin-bottom: 0;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const StyledImageListItemImage = styled.div`
    ${(props) => `background-image: url('${props.url}');`};
    border-radius: 0.75vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    flex: 1;

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    @media (min-width: 600px) {
        width: 100%;
        margin-bottom: 2vw;
    }
`;

export const StyledImageListItemText = styled.div`
    display: flex;
    flex: 2;
    text-align: left;
    margin-left: 4vw;
    font-size: 4.4vw;
    line-height: normal;

    @media (min-width: 600px) {
        flex: 1;
        text-align: center;
        font-size: ${mealtimeThemeFontSizes.contentFontSize};
        margin-left: 0;
    }
`;
