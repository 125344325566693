import fetch from 'cross-fetch';

export const serverEndpoint = (endpoint) => `/pages${endpoint}`;

export const get = async (endpoint, options?: object) => {
    const response = await fetch(endpoint, {
        ...options,
        method: 'GET'
    });

    if (!response.ok) {
        throw new Error('Get error');
    }

    return response;
};

export const post = async (endpoint, payload) => {
    const response = await fetch(endpoint, {
        ...payload,
        method: 'POST'
    });

    if (!response.ok) {
        throw new Error('Post error');
    }

    return response.json();
};
