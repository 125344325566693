import {get, serverEndpoint} from './client-fetch-service';

export const getSanityService = async (endpoint) => {
    const backendServerResponse = await get(serverEndpoint(endpoint), {
        headers: {
            'Content-Type': 'application/json'
        }

    });

    return backendServerResponse.json();
};

