import React, {useState, useCallback} from 'react';
import {PlayIcon, PauseIcon} from '@hy-vee/icons';

import {ScreenSize} from '../../contexts/screen-context';
import {orderNow} from '../../utils/mealtime-utils';

import {
    StyledVideoHeader,
    StyledVideoHeaderHeader,
    StyledVideoHeaderOverlayHeroButton,
    StyledVideoHeaderContent,
    StyledVideoHeaderOverlayHeroText,
    StyledVideoHeaderOverlayImage,
    StyledVideoHeaderPlaybackControls,
    StyledVideoHeaderVideo,
    StyledVideoHeaderFooter,
    StyledVideoHeaderFooterBrandLogos,
    StyledVideoHeaderFooterBrandLogo,
    StyledVideoHeaderFooterTitle,
    StyledVideoHeaderFooterDescription,
    StyledVideoHeaderFooterButton
} from './mt-cms-video-header.styles';

const playPauseIcon = (playing) =>
    playing ?
        <PauseIcon
            color="white"
            data-testid="mealtime-header-video-pause-icon"
            name="pause"
            size="large"
        />
        :
        <PlayIcon
            color="white"
            data-testid="mealtime-header-video-play-icon"
            name="play"
            size="large"
        />;

const parseBrandLogos = (photoCards) =>
    photoCards.map((logo) => (
        <StyledVideoHeaderFooterBrandLogo key={logo.imageAlt}>
            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
            <img
                alt={logo.imageAlt}
                role="img"
                src={logo.image.asset.url}
            />
        </StyledVideoHeaderFooterBrandLogo>
    ));

const MtCMSVideoHeader = ({heroBanner, brandLogos, videoHeaderFooter}) => {
    const [video, setVideo] = useState(null);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const vidRef = useCallback((node) => {
        setVideo(node);

        if (node !== null) {
            node.addEventListener('pause', () => setVideoPlaying(false));
            node.addEventListener('play', () => setVideoPlaying(true));
        }
    }, []);
    const handlePlayPauseVideo = () => {
        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }
    };

    return (
        <ScreenSize>
            {({screenWidth}) =>
                <StyledVideoHeader>
                    <StyledVideoHeaderHeader
                        backgroundImageUrl={heroBanner.backgroundImage.asset.url}
                        videoHeight={680}
                        videoWidth={1440}
                    >
                        {screenWidth >= 600 ?
                            <StyledVideoHeaderVideo
                                autoPlay
                                data-testid={heroBanner.backgroundVideoAlt}
                                loop
                                muted
                                ref={vidRef}
                                src={heroBanner.backgroundVideoUrl}
                            />
                            :
                            null}
                        <StyledVideoHeaderContent>
                            <StyledVideoHeaderOverlayImage
                                alt={heroBanner.logoAltText}
                                src={heroBanner.logo.asset.url}
                            />
                            <StyledVideoHeaderOverlayHeroText>{heroBanner.header}</StyledVideoHeaderOverlayHeroText>
                            <StyledVideoHeaderOverlayHeroButton
                                data-testid="mealtime-header-order-now-button-desktop-1"
                                onClick={() => orderNow(heroBanner.buttons[0].buttonLink)}
                                size="large"
                            >
                                {heroBanner.buttons[0].buttonText}

                            </StyledVideoHeaderOverlayHeroButton>
                        </StyledVideoHeaderContent>
                        {screenWidth >= 600 ?
                            <StyledVideoHeaderPlaybackControls
                                data-testid="mealtime-header-video-play-pause-button"
                                onClick={handlePlayPauseVideo}
                            >
                                {playPauseIcon(videoPlaying)}
                            </StyledVideoHeaderPlaybackControls>
                            :
                            null}
                    </StyledVideoHeaderHeader>
                    <StyledVideoHeaderFooter>
                        <StyledVideoHeaderFooterBrandLogos>
                            {parseBrandLogos(brandLogos.photoCards)}
                        </StyledVideoHeaderFooterBrandLogos>
                        <StyledVideoHeaderFooterTitle>
                            {videoHeaderFooter.headers.map((header) =>
                                <div key={`qa-${header}`}>{header}</div>
                            )}
                        </StyledVideoHeaderFooterTitle>
                        <StyledVideoHeaderFooterDescription>{videoHeaderFooter.contentRaw[0].children[0].text}</StyledVideoHeaderFooterDescription>
                        <StyledVideoHeaderFooterButton
                            data-testid="mealtime-header-order-now-button-mobile-1"
                            onClick={() => orderNow(videoHeaderFooter.buttons[0].buttonLink)}
                        >
                            {videoHeaderFooter.buttons[0].buttonText}

                        </StyledVideoHeaderFooterButton>
                    </StyledVideoHeaderFooter>
                </StyledVideoHeader>}
        </ScreenSize>
    );
};

export default MtCMSVideoHeader;
