import styled from 'styled-components';
import {H2} from '@hy-vee/web-core/lib/components/typography';
import {Button} from '@hy-vee/web-core/lib/components/button';

export const StyledIEFullWidthImage = styled.img`
    width: 100%;
    height: auto;
`;

export const StyledIEPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    line-height: 1.3;
`;

export const StyledIEPageContent = styled.div`
    width: 1000px;
`;

export const StyledIEHeader = styled.div`
    position: relative;
    width: 100%;
`;

export const StyledIEHeaderContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const StyledIEHeaderVideo = styled.video`
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const StyledIEHeaderHeroText = styled.div`
    font-size: 80px;
    font-weight: 700;
    line-height: 1.1;
    color: white;
`;

export const StyledIEHeaderHeroLogo = styled.img`
    margin-bottom: 20px;
`;

export const StyledIEHeaderHeroButton = styled(Button)`
    margin-top: 20px;
`;

export const StyledIEBrandLogos = styled.div`
    margin-top: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 70px;
    padding-right: 70px;
`;

export const StyledIEBrandLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 80px;
    margin-right: 40px;

    &:last-child {
        margin-right: 0;
    }

    img {
        height: 80px;
        width: auto;
    }
`;

export const StyledIEHeadline = styled.div`
   width: 100%;
   padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const StyledIEHeadlineText = styled.div`
    display: block;
    margin-bottom: 12px;

    div {
        display: inline;
        font-size: 40px;
        padding-left: 20px;
        padding-right: 20px;
        border-right: 2px solid ${(props) => props.theme.primary};
    }

    div:last-child {
        border-right: none;
    }
`;

export const StyledIEHeadlineSubext = styled.div`
    text-align: center;
    display: block;
    white-space: pre-line;
    font-size: 30px;
`;

export const StyledIECurbside = styled.div`
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
`;

export const StyledIECurbsideItem = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 30px;

    &:last-child {
        margin-right: 0;
    }
`;

export const StyledIECurbsideItemImage = styled.div`
    ${(props) => `background-image: url('${props.url}');`};
    margin-bottom: 16px;
    width: 100%;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
`;

export const StyledIECurbsideItemText = styled.div`
    width: 100%;
    text-align: center;
    oveflow: hidden;
    overflow-wrap: break-word;
    word-wrap: normal;
`;

export const StyledIEDelivery = styled.div`
    margin-top: 30px;
    position: relative;
    width: 100%;
`;

export const StyledIEDeliveryContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
`;

export const StyledIEDeliveryFloatingBox = styled.div`
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    margin: 28px;
    width: 33%;
    padding-bottom: 30px;
`;

export const StyledIEDeliveryFloatingBoxCap = styled.div`
    background-color: ${(props) => props.theme.primary};
    width: 100%;
    height: 6%;
`;

export const StyledIEDeliveryFloatingBoxTitle = styled(H2)`
    white-space: pre-line;
    text-align: center;
`;

export const StyledIEDeliveryFloatingBoxDivider = styled.div`
    background-color: ${(props) => props.theme.primary};
    width: 50%;
    height: 2px;
`;

export const StyledIEDeliveryFloatingBoxText = styled.div`
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    text-align: center;
    white-space: pre-line;
    oveflow: hidden;
    overflow-wrap: break-word;
    word-wrap: normal;
`;

export const StyledIEQuestionAndAnswers = styled.div`
    width: 100%;
    columns: 2;
    column-gap: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
`;

export const StyledIEQuestionAndAnswer = styled.div`
`;

export const StyledIEQuestion = styled.p`
    font-weight: 500;
    margin-bottom: 2px;

    &:before {
        content: "Q: "
    }
`;

export const StyledIEAnswer = styled.p`
    &:before {
        content: "A: "
    }

    margin-bottom: 20px;
`;
