import styled from 'styled-components';

export const StyledRelativeContainer = styled.div`
    position: relative;
    font-family: var(--font-family);
`;

export const ModalContent = styled.div`
`;

export const ModalFooter = styled.div`
    > * {
        margin: 0 3px;
    }

    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

