import styled from 'styled-components';

import {mealtimeThemeFontSizes} from '../../styles/mealtime-theme';

export const StyledQuestionsAndAnswers = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3vw;
    padding-left: 6vw;
    padding-right: 6vw;
    background-color: #F5F5F5;
    margin-top: 30px;

    @media (min-width: 600px) {
        columns: 2;
        background-color: initial;
        padding-top: 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;

export const StyledQuestionsAndAnswersTitle = styled.h2`
    font-size: 24px;
    text-align: center;

    @media (min-width: 600px) {
        font-size: ${mealtimeThemeFontSizes.titleFontSize};
    }
`;

export const StyledQuestionsAndAnswersTitleSeparator = styled.div`
    background-color: ${(props) => props.theme.primary};
    width: 20%;
    height: 2px;
    margin-top: 10px;
    margin-bottom: 20px;
`;

export const StyledQuestionsAndAnswersCollection = styled.div`
    @media (min-width: 600px) {
        columns: 2;
        column-gap: 40px;
    }
`;

export const StyledQuestionAndAnswer = styled.div`
    break-inside: avoid-column;
`;

export const StyledQuestion = styled.p`
    font-weight: 500;

    &:before {
        content: "Q: "
    }
`;

export const StyledAnswer = styled.p`
    &:before {
        content: "A: "
    }

    margin-bottom: 8px;
`;
